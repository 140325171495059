import './searchBar.css';
import React, {useState} from 'react';
import { FiSearch } from 'react-icons/fi'


function SearchBar(props) {

    const [serial, setSerial] = useState("");

    // capturing event target value and outputting it to input field value
    const onChange = (e) => {
        setSerial(e.target.value);
      };


    // check input value and perform action
      const handleSubmit = e => {
          e.preventDefault()
          props.getFirmwareProp(serial)
      }

      
    

    return (
        <div className="searchBar">
        <form onSubmit={handleSubmit}>
                <input name="searchBar" type="text" className="searchInput" autoFocus placeholder="Serial number of touchscreen" value={serial} onChange={onChange} />
                <button type="submit" className="searchButton"><FiSearch className="searchIcon" /></button>
            </form>
        </div>
    );
}

export default SearchBar;