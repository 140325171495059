import "./results.css";

function Results(props) {
  // show results of not found
  if (props.searchResultsProp.message === "NF") {
    return (
      <div className="results">
        <p className="warning">Serial not found. Please try again</p>
      </div>
    );
    // show results when SN is found
  } else if (props.searchResultsProp.message) {
    return (
      <div className="results">
        <div className="output">
        <span className="firmwareAvailable">A firmware is available!</span>
          <table className="outputTable">
            <tr>
              <td>Serial:</td>
              <td>{props.searchResultsProp.message.serial}</td>
            </tr>
            <tr>
              <td>Display model:</td>
              <td>{props.searchResultsProp.message.model} <span className="hiddenID">{props.searchResultsProp.message.panel_id}</span></td>
            </tr>
            <tr>
              <td>Initial firmware:</td>
              <td>{props.searchResultsProp.message.initfirm}</td>
            </tr>
            <tr>
              <td>Latest firmware:</td>
              <td>{props.searchResultsProp.message.latest_firm}</td>
            </tr>
            <tr>
              <td>Registered:</td>
              <td>
                {props.registerResultsProp.message === "Registered" ? (
                  <span style={{ color: "green" }}>Yes</span>
                ) : (
                  <span style={{ color: "red" }}>No</span>
                )}
              </td>
            </tr>
          </table>
          <span className="warning">
            Warning! Loading the firmware onto the device will reset it to
            factory settings.
          </span>
          <a
            href={props.searchResultsProp.message.link}
            target="_blank"
            rel="noreferrer"
            className="downloadButton"
          >
            Download
          </a>
        </div>
      </div>
    );

    // default start state
  } else {
    return <div className="results"></div>;
  }
}

export default Results;
