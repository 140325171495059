import './container.css'

import Header from './header';
import SearchBar from './searchBar';
import Results from './results';
import axios from "axios"
import React, {useState} from 'react';



function Container() {


// Create state variable
const [firmwareInfo, setFirmwareInfo] = useState("")
const [registerInfo, setRegisterInfo] = useState("")

    function getFirmware(serial) {
        var data = JSON.stringify({"serial":serial});
    
        var configFW = {
          method: 'post',
          url: 'https://brixapi.azurewebsites.net/api/v1/brix/getFW',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };

       

        axios(configFW)
        .then(function (response) {
          
          // Update state with response from API
          setFirmwareInfo(response.data)

          var data = JSON.stringify({
            "serial": serial
          });
          
          var config = {
            method: 'post',
            url: 'https://brixapi.azurewebsites.net/api/v1/brix/checkRegistration',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            setRegisterInfo(response.data)
            
          })
          .catch(function (error) {
            console.log(error);
          });


            
          
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    return (
        <div className="container">
            <Header />
            <SearchBar getFirmwareProp={getFirmware}/>
            <Results searchResultsProp={firmwareInfo} registerResultsProp={registerInfo} />
        </div>
    )
}

export default Container;